.cpCoverCardList {
    padding: 8px;

    .cpCoverCardContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .moreBtn {
        margin-top: 16px;
        color: #d4d4d4;
        border: 1px solid #d4d4d4;
        background-color: initial;
    }
}